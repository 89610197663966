









































import {Component, Prop, Vue} from "vue-property-decorator";
import {displayRole} from "@/models/Role";
import TextInput from "@/components/form/TextInput.vue";
import FormButton from "@/components/form/Button.vue";
import VueSelect from "vue-select";
import axios from "axios";

@Component({
  components: {
    TextInput,
    FormButton,
    VueSelect,
  },
  props: ["user", "expanded", "roles"],
})
export default class ControlPanelUser extends Vue {
  @Prop()
  public user: any;

  @Prop()
  public roles: any[];

  public editName = this.user.name || "";
  public editNameWarning = false;

  public editRole = this.user.role_id;

  public editEmail = this.user.email;
  public editEmailWarning = false;

  public editInstitute = this.user.institute || "";
  public editInstituteWarning = false;

  public editCity = this.user.city || "";
  public editCityWarning = false;

  public editCountry = this.user.country || "";
  public editCountryWarning = false;

  @Prop()
  public expanded: boolean;

  public validate() {
    this.editNameWarning = !this.editName.trim();
    this.editEmailWarning = !emailRegex.test(this.editEmail);
    this.editCityWarning = !this.editCity.trim();
    this.editCountryWarning = !this.editCountry.trim();
    this.editInstituteWarning = !this.editInstitute.trim();

    return !(this.editNameWarning || this.editEmailWarning ||
      this.editCityWarning || this.editCountryWarning || this.editInstituteWarning);
  }

  public saveUser() {
    const changedUser = {
      name: this.editName,
      role_id: this.editRole,
      email: this.editEmail,
      institute: this.editInstitute,
      city: this.editCity,
      country: this.editCountry,
    };

    if (this.validate()) {
      axios.patch("/controlpanel/users/" + this.user.id, changedUser).then(() => {
        this.toggleExpand();
        this.$emit("edit", {
          user: changedUser,
          id: this.user.id,
          role: this.roles.find((role) => role.id == this.editRole).name,
        });
      });
    }
  }

  public deleteUser() {
    if (confirm("Are you sure you want to delete this user?")) {
      axios.delete("/controlpanel/users/" + this.user.id).then(() => {
        this.toggleExpand();
        this.$emit("delete", this.user.id);
      });
    }
  }

  public toggleExpand() {
    this.$emit("toggleExpand", this.user.id);
  }

  public dropdownRole(role: any) {
    return displayRole(role);
  }

  public activateUser() {
    axios.patch("/controlpanel/users/" + this.user.id, {
      active: true,
    }).then(() => {
      this.$emit("activateEmail", this.user.id);
    });
  }

  get displayRole() {
    return displayRole(this.user.role);
  }
}
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;














import {Component, Prop, Vue} from "vue-property-decorator";

@Component({
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    totalPages: {
      type: Number,
      required: true,
    },
  },
})
export default class PageControls extends Vue {
  @Prop(Number)
  public currentPage: number;
  @Prop(Number)
  public totalPages: number;

  public forward() {
    if (this.forwardEnabled) {
      this.$emit("forward");
    }
  }

  public back() {
    if (this.backEnabled) {
      this.$emit("back");
    }
  }

  get forwardEnabled() {
    return this.currentPage < this.totalPages;
  }

  get backEnabled() {
    return this.currentPage > 1;
  }
}


















import {Component, Vue} from "vue-property-decorator";

@Component({})
export default class SearchBar extends Vue {
  public query = "";
  public searchActive = false;

  public submit() {
    this.$emit("submit", this.query);
    this.searchActive = true;
  }

  public clear() {
    this.$emit("clear");
    this.query = "";
    this.searchActive = false;
  }
}


















import {Component, Vue} from "vue-property-decorator";
import axios from "axios";
import ControlPanelUser from "@/components/controlpanel/users/ControlPanelUser.vue";
import SearchBar from "@/components/controlpanel/users/SearchBar.vue";
import PageControls from "@/components/controlpanel/users/PageControls.vue";

@Component({
  components: {
    ControlPanelUser,
    SearchBar,
    PageControls,
  },
})
export default class UsersControlPanel extends Vue {
  public users: any[] = [];
  public roles: any[] = [];

  public currentPage: number = 1;
  public totalPages: number = 1;
  public searchQuery = "";
  public expandedId = -1;

  public created() {
    this.getUsers();
    axios.get("/controlpanel/users/roles").then((res) => {
      this.roles = res.data;
    });
  }

  public search(query: string) {
    this.searchQuery = query;
    this.currentPage = 1;
    this.getUsers();
  }

  public clearSearch() {
    this.searchQuery = "";
    this.currentPage = 1;
    this.getUsers();
  }

  public forward() {
    this.currentPage++;
    this.getUsers();
  }

  public back() {
    this.currentPage--;
    this.getUsers();
  }

  public isExpanded(id: number) {
    return this.expandedId == id;
  }

  public toggleExpand(id: number) {
    if (id == this.expandedId) {
      this.expandedId = -1;
    } else {
      this.expandedId = id;
    }
  }

  public editUser({user, id, role}: any) {
    const original = this.users.find((u) => u.id == id);
    original.name = user.name;
    original.role = role;
    original.email = user.email;
    original.institute = user.institute;
    original.city = user.city;
    original.country = user.country;
  }

  public activateEmail(id: number) {
    const original = this.users.find((u) => u.id == id);
    original.active = true;
  }

  public deleteUser(id: number) {
    this.users.splice(this.users.findIndex((u) => u.id == id), 1);
  }

  public getUsers() {
    axios.get("/controlpanel/users", {params: {q: this.searchQuery, page: this.currentPage}}).then((res) => {
      this.currentPage = +res.data.pagination.current_page;
      this.totalPages = +res.data.pagination.total_pages;
      this.users = res.data.data;
    });
  }
}
